@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:regular,bold,italic,light,extralight&subset=latin,latin-ext');

body {
  padding: 0;
  margin: 0;
  height: 100vh;
  max-width: 100vw;
  overflow-x: scroll;
}

body,
p,
span,
tspan {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.dropzone-header-custom {
  display: none !important;
}